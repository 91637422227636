import { BtnClose } from '../Button';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

export const PopCcontainer = styled.div`
  padding: 0 32px;

  ${breakpoint(`tablet`)} {
    padding: 0 20px;
  }
`;

export const PopupTitle = styled.div`
  border-bottom: 1px solid #1cabe2;
  width: 100%;
  position: relative;
  padding: 17px 0;
  padding-right: 50px;

  h2 {
    strong {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ${BtnClose} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    &::before,
    &::after {
      background: #1cabe2;
    }
  }
`;
